<template>
  <div class="auth-wrapper auth-v2">

  

    <b-row  v-if="!isMobile" class="auth-inner m-0">

      <!-- Brand logo-->

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
      cols="12"
      md="12"
      xl="8"
        lg="8"
       style="    padding: 0px;"
        class="d-none d-lg-flex align-items-center"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center" style="padding: 0px;
    height: 100vh;">

<v-lazy-image src="/img/fondoEqcoop.svg" style="    object-fit: cover;
    object-position: center top;
    height: 100%;
    width: 100%;" />


        </div>
      </b-col>
      <!-- /Left Text-->


      <b-col
      cols="12"
      md="12"
      xl="4"
        lg="4"
        class="d-flex align-items-center auth-bg"
      >

        <b-row>

               <PWAPrompt v-if="shown" :install-event="installEvent"/>

          <b-col
            sm="12"
            md="12"
            lg="12"
            align="center"
            class="mx-auto"
          >

            <img

            width="225px"
            height="100px"
              alt="EQCoop"
              src="/img/eqcoop.svg"
            >

          </b-col>

          <b-col
            sm="12"
            md="12"
            lg="12"
            class="mx-auto"
          >
            <b-card-title
              class=" bienvenido"
              title-tag="h2"
            >
            La primera cooperativa digital del Ecuador
            </b-card-title>

          </b-col>


          

          <b-col
            sm="12"
            md="12"
            lg="12"
            class="mb-1 mx-auto"
          >
            <b-card-text class="centrado">
              ¿Cómo deseas ingresar?

            </b-card-text>

          </b-col>

          
          <formulario :is-login="isLogin" :promo-code="promoCode" :texto-button="textoButton" />

        


       

          <b-col
            style="color:black !important;"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center align-items-center justify-content-xl-between"
          >
          <hr>

            <a
              target="_blank"
              title="Facebook de EQCoop"
              href="https://www.facebook.com/eqpay.oficial"
            >   <feather-icon
             icon="FacebookIcon"
             size="21"
             class="redes"
             /></a>
            <a
              target="_blank"
              title="Instagram de EQCoop"
              href="https://www.instagram.com/eqpay.oficial"
            > <feather-icon
             icon="InstagramIcon"
             size="21"
             class="redes"
             /></a>
            <a
              target="_blank"
              title="Email de EQCoop"
              href="mailto:hola@eqcoops.com?Subject=Tengo%20una%20pregunta"
            > <feather-icon
             icon="MailIcon"
             size="21"
             class="redes"
             /></a>
            <a
              target="_blank"
              title="Tiktok de EQCoop"
              href="https://www.tiktok.com/@eqpays?_t=8WRpV7M3v65&_r=1"
            > <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#00838C"
              widht="10"
              height="19"
              viewBox="0 0 448 512"
            ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

            <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> <a
              href="https://web.eqcoops.com"
              target="_blank"
              class="hover_link"
            >Conoce más sobre EQCoop</a> - <a
                  href="https://backservicespagos.web.app/"
                  target="_blank"
                  class="hover_link"
                >Nuestros negocios afiliados</a> - <b-link
              :to="{ name: 'privacidad'}"
              target="_blank"
              class="hover_link"
            >Política de privacidad</b-link></p>

            <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> Copyright &copy; {{ year }} <a
              href="https://web.eqcoops.com"
              target="_blank"
              class="hover_link"
            >- EQCoop</a></p>

          </b-col>

        </b-row>

      </b-col>
    <!-- /Login-->

         
    </b-row>

    <b-row  v-else class="auth-inner m-0">

      <b-col
          cols="12"
          sm="12"
          md="2"
          xl="2"
            lg="2"></b-col>

    
          <b-col
          cols="12"
          sm="12"
          md="8"
          xl="8"
            lg="8"
            class="d-flex align-items-center auth-bg"
          >

            <b-row>

                  <PWAPrompt v-if="shown" :install-event="installEvent"/>

              <b-col
                sm="12"
                md="12"
                lg="12"
                align="center"
                class="mx-auto"
              >

                <img

                width="225px"
                height="100px"
                  alt="EQCoop"
                  src="/img/eqcoop.svg"
                >

              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="12"
                class="mx-auto"
              >
                <b-card-title
                  class="bienvenido"
                  title-tag="h2"
                >
                La primera cooperativa digital del Ecuador
                </b-card-title>

              </b-col>


              

              <b-col
                sm="12"
                md="12"
                lg="12"
                class="mb-1 mx-auto"
              >
                <b-card-text class="centrado">
                  ¿Cómo deseas ingresar?

                </b-card-text>

              </b-col>

              
              <formulario :is-login="isLogin" :promo-code="promoCode" :texto-button="textoButton" />

            


          

              <b-col
                style="color:black !important;"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="text-center align-items-center justify-content-xl-between"
              >
              <hr>

            
              
            

                <a
                  target="_blank"
                  title="Facebook de EQCoop"
                  href="https://www.facebook.com/eqpay.oficial"
                >   <feather-icon
                icon="FacebookIcon"
                size="21"
                class="redes"
                /></a>
                <a
                  target="_blank"
                  title="Instagram de EQCoop"
                  href="https://www.instagram.com/eqpay.oficial"
                > <feather-icon
                icon="InstagramIcon"
                size="21"
                class="redes"
                /></a>
                <a
                  target="_blank"
                  title="Email de EQCoop"
                  href="mailto:hola@eqcoops.com?Subject=Tengo%20una%20pregunta"
                > <feather-icon
                icon="MailIcon"
                size="21"
                class="redes"
                /></a>
                <a
                  target="_blank"
                  title="Tiktok de EQCoop"
                  href="https://www.tiktok.com/@eqpays?_t=8WRpV7M3v65&_r=1"
                > <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#00838C"
                  widht="10"
                  height="19"
                  viewBox="0 0 448 512"
                ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

                <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> <a
                  href="https://web.eqcoops.com"
                  target="_blank"
                  class="hover_link"
                >Conoce más sobre EQCoop</a> - <a
                  href="https://backservicespagos.web.app/"
                  target="_blank"
                  class="hover_link"
                >Nuestros negocios afiliados</a>  - <b-link
                  :to="{ name: 'privacidad'}"
                  target="_blank"
                  class="hover_link"
                >Política de privacidad</b-link> </p>

                <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> Copyright &copy; {{ year }} <a
                  href="https://web.eqcoops.com"
                  target="_blank"
                  class="hover_link"
                >- EQCoop</a></p>

              </b-col>

            </b-row>

          </b-col>
          <!-- /Login-->

         
      <b-col
          cols="12"
          sm="12"
          md="2"
          xl="2"
            lg="2"></b-col>
          </b-row>
  </div>

 

</template>

<script>
/* eslint-disable global-require */

import {
  BRow, BCol, BAlert, BLink, BCardText, BCardTitle, BImg, BButton
} from 'bootstrap-vue'

import PWAPrompt from './PWAPrompt.vue'
import Formulario from './Formulario.vue'
import VLazyImage from "v-lazy-image/v2";
export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    PWAPrompt,
    VLazyImage,
    Formulario
},
  data() {

    let displayMode = 'browser tab';
      if (window.matchMedia('(display-mode: standalone)').matches) {
        displayMode = 'standalone';
      }



    document.body.classList.remove('dark-layout')
    return {
      urlLanding: '',
      isMobile:this.$isMobile,
      shown:false,
      installEvent:null,
      year: new Date().getFullYear(),
      isLogin:true,
      promoCode:"",
      displayMode:displayMode,
      textoButton:"Iniciar sesión con"

    }
  },
  computed: {

  },
  watch: {
   
  },
  beforeCreate() {

  


  },
  created() {

  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },
  mounted() {

 


    this.$eventBus.$on('ocultarPWA', () => {
      this.shown= false
    });


    document.title = 'EQCoop - La primera cooperativa digital del Ecuador'


  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.conoce{
  text-align: center;    font-weight: bold;

            color: #2f2e72;
            font-size: 14px;
}

.conoce:hover{

            color: #00838C;
      
}


</style>
