<template>

  <b-row style="    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, .8)!important;
    bottom: 0;
    left: 0;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 101;
    padding: 0px 15px 0px 15px;">

<b-col cols="12" sm="12" md="12" lg="2" xl="2"></b-col>


    <b-col cols="2" sm="2" md="2" lg="1" xl="1" style="display:flex;" >

      <img style="width: 45px;margin:auto" src="/img/eqlogo.svg"/>

    </b-col>


    <b-col cols="10" sm="10" md="10" lg="4" xl="4">

      <p style="font-size: 20px;
    margin-bottom: 10px;
    margin-top: 15px;
    font-weight: bold;
    color: #fff;text-align: left; ">EQCoop es mejor en PWA</p>
    <p style="font-size: 14px;
    margin-bottom: 10px;
    color: #fff;
    text-align: left; ">Instala el PWA de EQCoop en tu {{device}} para utilizarlo como una aplicación sin la necesidad de que ingreses a un navegador.</p>

</b-col>

<b-col cols="12" sm="12" md="12" lg="3" xl="3" style="display:flex">

  <div align="center" style="    margin: auto;">
    <button style="margin-bottom: 10px; margin-right: 10px;" class="btn btn-primary_google btn-secondary" @click="installPWA">
      Instalar app
  </button>

<button style="margin-bottom: 10px; 
    background-color: #f0f2f5 !important;
    color: black !important;border:2px solid white !important" class="btn btn-primary_google btn-secondary"  @click="dismissPrompt" >
    Ahora no
  </button>


</div>

</b-col>

<b-col cols="12" sm="12" md="12" lg="2" xl="2"></b-col>



   



  </b-row>



</template>


<script>

import {
  BRow, BCol, 
} from 'bootstrap-vue'

  export default {
    components: {
      BRow, BCol, 
    },
    props: ['installEvent'],
    data() {

      const ua = navigator.userAgent

        let device="";


        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
          //es tablet
          device="tablet";

        } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        //es mobile
        device="celular";

        } else {
          //es destopk
          device="computadora";
          


        }
    
        
      return {
        device:device

      }
    },
    created() {
      // data
  
      /*
      this.$http.get('/analytics/data')
        .then(response => { this.data = response.data })
  
        */
    },
    beforeMount() {

  },
    mounted() {
  
     
  
    },
    methods: {

      dismissPrompt() {
        this.$eventBus.$emit('ocultarPWA')
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  
  
    },
  }
  </script>




